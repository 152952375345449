<template>
  <button
    :type="type"
    :class="[
      { 'opacity-50 cursor-not-allowed': loading || disabled },
      color,
      radius,
      width,
      padding
    ]"
    :disabled="
      loading || (props.enableClickDisableMode == true ? false : disabled)
    "
    :aria-label="title || t('submit')"
    @click="submit"
  >
    <EStoreLoadingIndicator :loading="loading" color="white" :class="textColor">
      <v-icon v-if="icon" :icon="icon" :size="20" />
      {{ title || t('submit') }}
    </EStoreLoadingIndicator>
  </button>
</template>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    title?: string
    type?: 'button' | 'submit' | 'reset' | undefined
    loading?: boolean
    color?: string
    radius?: string
    width?: string
    padding?: string
    textColor?: string
    icon?:string
    disabled?: boolean
    enableClickDisableMode?: boolean
  }>(),
  {
    title: undefined,
    icon: undefined,
    color: 'bg-nav_color',
    textColor: 'text-text_alt_color',
    type: 'button',
    radius: 'rounded',
    width: 'w-full',
    padding: 'p-2',
    loading: false,
    disabled: false
  }
)

const emit = defineEmits(['submit'])

const { t } = useI18n()

const submit = (e: Event) => {
  ;(props.enableClickDisableMode === true ? true : !props.disabled) &&
    !props.loading &&
    emit('submit', e)
}
</script>
